// @flow
import React from 'react';
import cx from 'classnames';

import { gtag } from '../../utils';

import styles from './Modal.module.scss';

const Modal = ({
  isModalOpen,
  toggleIsModalOpen,
}: {
  isModalOpen: boolean,
  toggleIsModalOpen: Function,
}) => {
  const preventCloseModal = (e) => e.stopPropagation();

  return (
    <div
      role="presentation"
      className={cx(styles.modal, { [styles.open]: isModalOpen })}
      onClick={toggleIsModalOpen}
    >
      <section
        role="presentation"
        className={styles.info}
        onClick={preventCloseModal}
      >
        <button onClick={toggleIsModalOpen} className={styles.close}>
          <img
            src={require('../../images/icon-times.svg')}
            alt="close"
            width="17"
            height="17"
            loading="lazy"
            className={styles.pc}
          />
          <img
            src={require('../../images/icon-caret-down.svg')}
            alt="close"
            height="24"
            loading="lazy"
            className={styles.sp}
          />
        </button>
        <h4 className={styles.modalTitle}>電話でのお問い合わせ</h4>
        <a
          href="tel:0120971918"
          className={styles.tel}
          onClick={() => {
            gtag({
              event: 'user_action',
              ga_action: 'click_contact_tel',
            });
          }}
        >
          0120-971-918
        </a>
      </section>
    </div>
  );
};

export default Modal;
