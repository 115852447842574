// @flow
import React from 'react';

import styles from './HowWeProtectYou.module.scss';

const HowWeProtectYou = () => {
  return (
    <section className={styles.section}>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionHeader}>
          <h3 className={styles.sectionTitle}>
            {`お客様に安心してペイディをご利用いただくために`}
          </h3>
        </div>

        <ul className={styles.figureList}>
          <li className={styles.figureListItem}>
            <figure>
              <div className={styles.imageWrapper}>
                <img
                  src={require('../../images/icon-security-pincode.svg')}
                  alt="認証コードによる本人確認"
                  loading="lazy"
                  width="240"
                  className={styles.pincode}
                />
              </div>
              <figcaption className={styles.caption}>
                認証コードによる本人確認
              </figcaption>
            </figure>
            <p>
              決済画面で入力した携帯電話番号宛に4桁の認証コードをSMSでお届け。認証コード入力をもって決済が完了するため、なりすましの心配がありません。
              <br />
              <br />
              <br />
            </p>
          </li>
          <li className={styles.figureListItem}>
            <figure>
              <div className={styles.imageWrapper}>
                <img
                  src={require('../../images/icon-security-kyc.svg')}
                  alt="さらなる本人確認の徹底・強化"
                  loading="lazy"
                  width="240"
                  className={styles.kyc}
                />
              </div>
              <figcaption className={styles.caption}>
                さらなる本人確認の徹底・強化
              </figcaption>
            </figure>
            <p>
              {`必要書類 ※をオンライン上で提出いただき、本人確認をさらに徹底します。より強固なセキュリティ体制で、詐欺行為やなりすまし行為を防ぎます。`}
              <br />
              <br />
              <span className={styles.note}>
                ※運転免許証、マイナンバーカードがご利用いただけます。
              </span>
            </p>
          </li>
          <li className={styles.figureListItem}>
            <figure>
              <div className={styles.imageWrapper}>
                <img
                  src={require('../../images/icon-security-tls.svg')}
                  alt="暗号化"
                  loading="lazy"
                  width="162"
                  className={styles.tls}
                />
              </div>
              <figcaption className={styles.caption}>
                決済情報はTLSで暗号化
              </figcaption>
            </figure>
            <p>
              お客様の大事な決済情報はTLS(Transport Layer
              Security)接続を介して暗号化されるため、第三者にお客様の決済情報が漏洩する危険がありません。
              <br />
              <br />
              <br />
            </p>
          </li>
          <li className={styles.figureListItem}>
            <figure>
              <div className={styles.imageWrapper}>
                <img
                  src={require('../../images/icon-security-monitor.svg')}
                  alt="監視"
                  loading="lazy"
                  width="245"
                  className={styles.monitoring}
                />
              </div>
              <figcaption className={styles.caption}>
                365日24時間体制で全決済を監視
              </figcaption>
            </figure>
            <p>
              詐欺行為やなりすまし行為からお客様を守るため、365日24時間体制で全ての取引を監視しています。
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default HowWeProtectYou;
