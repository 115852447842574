// @flow
import React from 'react';

import styles from './HowToProtectYourself.module.scss';

const HowToProtectYourself = () => {
  return (
    <section className={styles.section}>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionHeader}>
          <h3 className={styles.sectionTitle}>
            お客様にご注意
            <br />
            いただきたいこと
          </h3>
        </div>
        <div className={styles.boxContainer}>
          <div className={styles.box}>
            <div className={styles.iconItem}>
              <span className={styles.icon}>
                <img
                  src={require('../../images/icon-checkedbox.svg')}
                  alt="icon-checkbox"
                  width="22.76"
                  height="17.45"
                  loading="lazy"
                />
              </span>
              <p>
                携帯電話番号宛に送られた4桁の認証コードを他人に教えないでください
              </p>
            </div>
            <div className={styles.iconItem}>
              <span className={styles.icon}>
                <img
                  src={require('../../images/icon-checkedbox.svg')}
                  alt="icon-checkbox"
                  width="22.76"
                  height="17.45"
                  loading="lazy"
                />
              </span>
              <p>スマホ、携帯電話を放置しないでください</p>
            </div>
            <div className={styles.iconItem}>
              <span className={styles.icon}>
                <img
                  src={require('../../images/icon-checkedbox.svg')}
                  alt="icon-checkbox"
                  width="22.76"
                  height="17.45"
                  loading="lazy"
                />
              </span>
              <p>運営元が怪しいサイトを訪れないでください</p>
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.iconItem}>
              <span className={styles.icon}>
                <img
                  src={require('../../images/icon-checkedbox.svg')}
                  alt="icon-checkbox"
                  width="22.76"
                  height="17.45"
                  loading="lazy"
                />
              </span>
              <p>身に覚えのないメールやSMSをクリックしないでください</p>
            </div>
            <div className={styles.iconItem}>
              <span className={styles.icon}>
                <img
                  src={require('../../images/icon-checkedbox.svg')}
                  alt="icon"
                  width="22.76"
                  height="17.45"
                  loading="lazy"
                />
              </span>
              <p>
                CtoC（個人間）取引で住所と名前を取引相手に共有する際は十分に注意してください
              </p>
            </div>
            <div className={styles.iconItem}>
              <span className={styles.icon}>
                <img
                  src={require('../../images/icon-checkedbox.svg')}
                  alt="icon"
                  width="22.76"
                  height="17.45"
                  loading="lazy"
                />
              </span>
              <p>
                提供元が怪しいアプリをダウンロード、インストールしないでください
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToProtectYourself;
