// @flow
import React from 'react';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import styles from './Contact.module.scss';

const Contact = ({ toggleIsModalOpen }: { toggleIsModalOpen: Function }) => {
  return (
    <section id="Contact" className={styles.section}>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionHeader}>
          <h3 className={styles.sectionTitle}>
            ご連絡時に必要な情報は以下のとおりです
          </h3>
          <p className={styles.sectionSubtitle}>
            ペイディより詳細をお伺いいたします。
            <br />
            場合によってはさらに詳細の情報が必要となるケースがございます。何卒ご了承ください。
          </p>
        </div>
        <div className={styles.card}>
          <div className={styles.cardInner}>
            <ol className={styles.instruction}>
              <li className={styles.orderItem}>
                <span className={styles.orderBase}>
                  <span className={styles.orderInner}>
                    <span className={styles.order}>1</span>
                  </span>
                </span>
                お名前
              </li>
              <li className={styles.orderItem}>
                <span className={styles.orderBase}>
                  <span className={styles.orderInner}>
                    <span className={styles.order}>2</span>
                  </span>
                </span>
                携帯電話番号
              </li>
              <li className={styles.orderItem}>
                <span className={styles.orderBase}>
                  <span className={styles.orderInner}>
                    <span className={styles.order}>3</span>
                  </span>
                </span>
                ご住所
              </li>
              <li className={styles.orderItem}>
                <span className={styles.orderBase}>
                  <span className={styles.orderInner}>
                    <span className={styles.order}>4</span>
                  </span>
                </span>
                商品の送り元（発送元）
              </li>
              <li className={styles.orderItem}>
                <span className={styles.orderBase}>
                  <span className={styles.orderInner}>
                    <span className={styles.order}>5</span>
                  </span>
                </span>
                購入商品の詳細
              </li>
            </ol>
            <OutboundLink
              href="https://support.paidy.com/hc/ja/requests/new?noredirect=true"
              rel="noopener noreferrer"
              target="_blank"
              className={cx(styles.btnLink, styles.primary)}
            >
              <img
                className={styles.btnIcon}
                src={require('../../images/icon-email-white.svg')}
                alt="inquiry form"
                width="24"
                height="18"
                objectFit="contain"
                loading="lazy"
              />
              お問い合わせフォーム
            </OutboundLink>
            <button
              className={cx(styles.btnLink, styles.secondary)}
              onClick={toggleIsModalOpen}
            >
              <img
                className={styles.btnIcon}
                src={require('../../images/icon-mobile.svg')}
                alt="tel"
                height="24"
                loading="lazy"
              />
              <span className={styles.telBtn}>電話でお問い合わせ</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
