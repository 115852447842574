// @flow
import React, { useState } from 'react';

import Layout from '../components/Layout/Layout';
import HowWeProtectYou from '../components/Security/HowWeProtectYou';
import HowToProtectYourself from '../components/Security/HowToProtectYourself';
import HowToDeal from '../components/Security/HowToDeal';
import Contact from '../components/Security/Contact';
import Modal from '../components/Security/Modal';

import styles from '../styles/pages/security.module.scss';
import { SITE_METADATA } from '../constants';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';

const SecurityPage = ({ location }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleIsModalOpen = () => setIsModalOpen(!isModalOpen);

  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.security.title,
        description: SITE_METADATA.security.description,
      }}
    >
      <header className={styles.header}>
        <h2 className={styles.titlePc}>ペイディの安全への取り組み</h2>
        <img
          alt="title-sentence"
          src={require('../images/security-title-mobile.svg')}
          className={styles.titleMobile}
        />
      </header>
      <Breadcrumb location={location} backgroundColor="#fafafa" />
      <HowWeProtectYou />
      <HowToProtectYourself />
      <HowToDeal />
      <Contact toggleIsModalOpen={toggleIsModalOpen} />
      <Modal isModalOpen={isModalOpen} toggleIsModalOpen={toggleIsModalOpen} />
    </Layout>
  );
};

export default SecurityPage;
