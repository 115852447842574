// @flow
import React from 'react';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import styles from './HowToDeal.module.scss';

const HowToDeal = () => {
  return (
    <section className={styles.section}>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionHeader}>
          <h3 className={styles.sectionTitle}>
            こんな時はどうすれば
            <br />
            いいの？
          </h3>
          <p className={styles.sectionSubtitle}>
            身に覚えのない商品が送られてきたりペイディから身に覚えのない請求が来た場合、まずは、以下のご
            <br />
            確認をお願いいたします。
          </p>
        </div>
        <div className={styles.caseContainer}>
          <section className={styles.case}>
            <img
              src={require('../../images/icon-unfamiliar-item.png')}
              alt="身に覚えのない商品が送られてきた"
              className={styles.img}
              loading="lazy"
            />
            <div className={styles.contents}>
              <h4 className={styles.caption}>
                身に覚えのない商品が送られてきた
              </h4>
              <div className={styles.iconItem}>
                <span className={styles.icon}>
                  <img
                    src={require('../../images/icon-checkedbox.svg')}
                    alt="check-icon"
                    width="22.76"
                    height="17.45"
                    loading="lazy"
                  />
                </span>
                <span className={styles.iconItemDescription}>
                  ご家族や身の回りにいらっしゃる方がペイディをご利用されていませんか？ご家族に一度ご確認ください。
                </span>
              </div>
              <div className={styles.iconItem}>
                <span className={styles.icon}>
                  <img
                    src={require('../../images/icon-checkedbox.svg')}
                    alt="check-icon"
                    width="22.76"
                    height="17.45"
                    loading="lazy"
                  />
                </span>
                <span className={styles.iconItemDescription}>
                  ご友人や知人の方がペイディをご利用されていませんか？ご友人、知人の方に一度ご確認ください。
                </span>
              </div>
              <div className={styles.iconItem}>
                <span className={styles.icon}>
                  <img
                    src={require('../../images/icon-checkedbox.svg')}
                    alt="check-icon"
                    width="22.76"
                    height="17.45"
                    loading="lazy"
                  />
                </span>
                <span className={styles.iconItemDescription}>
                  商品は、本当に覚えのない商品ですか？送り主のお名前を今一度ご確認ください。
                </span>
              </div>
              <p className={styles.unresolvedCase}>
                以上を確認されてもご解決いただけない場合は、大変恐れ入りますが、カスタマーサポートまで
                <OutboundLink
                  href="https://support.paidy.com/hc/ja/requests/new?noredirect=true"
                  rel="noopener noreferrer"
                  target="_blank"
                  className={styles.link}
                >
                  お問い合わせ
                </OutboundLink>
                ください。
              </p>
            </div>
          </section>
          <section className={styles.case}>
            <img
              className={cx(styles.img, styles.bill)}
              src={require('../../images/icon-unfamiliar-bill.png')}
              alt="ペイディから身に覚えのない請求が来た"
              loading="lazy"
            />
            <div className={cx(styles.contents, styles.box)}>
              <h4 className={styles.caption}>
                ペイディから身に覚えのない請求が来た
              </h4>
              <div className={styles.iconItem}>
                <span className={styles.icon}>
                  <img
                    src={require('../../images/icon-checkedbox.svg')}
                    alt="check-icon"
                    width="22.76"
                    height="17.45"
                    loading="lazy"
                  />
                </span>
                <p className={styles.iconItemDescription}>
                  請求書の差出人は<span className={styles.bold}>ペイディ</span>
                  ですか？お客様のお手元に届いた請求が
                  <span className={styles.bold}>ペイディ</span>
                  からの請求でない可能性もございます。お手数ですが請求者の名前を今一度ご確認ください。
                </p>
              </div>
              <div className={styles.iconItem}>
                <span className={styles.icon}>
                  <img
                    src={require('../../images/icon-checkedbox.svg')}
                    alt="check-icon"
                    width="22.76"
                    height="17.45"
                    loading="lazy"
                  />
                </span>
                <p className={styles.iconItemDescription}>
                  ペイディのご利用はMyペイディでご確認いただけます。携帯番号とメールアドレスで
                  <OutboundLink
                    className={styles.link}
                    href="https://my.paidy.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    https://my.paidy.com
                  </OutboundLink>
                  にログインの上、請求内容の詳細をご確認ください。
                </p>
              </div>
              <div className={styles.iconItem}>
                <span className={styles.icon}>
                  <img
                    src={require('../../images/icon-checkedbox.svg')}
                    alt="check-icon"
                    width="22.76"
                    height="17.45"
                    loading="lazy"
                  />
                </span>
                <p className={styles.iconItemDescription}>
                  ペイディから送信するEメールの請求はメールアドレスの差出人が
                  <a
                    href="mailto:noreply@paidy.com"
                    className={styles.link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    noreply@paidy.com
                  </a>
                  となっています。差出人のメールアドレスをご確認ください。
                </p>
              </div>
              <p className={styles.unresolvedCase2}>
                以上を確認されてもご解決いただけない場合は、大変恐れ入りますが、カスタマーサポートまで
                <OutboundLink
                  href="https://support.paidy.com/hc/ja/requests/new?noredirect=true"
                  rel="noopener noreferrer"
                  target="_blank"
                  className={styles.link}
                >
                  お問い合わせ
                </OutboundLink>
                ください。
              </p>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default HowToDeal;
